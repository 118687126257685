import { OktaAuth, IDToken, AccessToken, UserClaims } from '@okta/okta-auth-js';

export function getOktaDomain() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_OKTA_DOMAIN;
  } else {
    return 'owg.okta.com';
  }
}

export function getOktaClientId() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_OKTA_CLIENT_ID;
  } else {
    return '0oaixi799wA00CifQ2p7';
  }
}

export function getOktaAuthServerId() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_OKTA_AUTH_SERVER_ID;
  } else {
    return 'ausixicb5eILCbXEG2p7';
  }
}

export function getDatadogApplicationId() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_DATADOG_APPLICATION_ID;
  } else {
    return '7f80635c-bbaa-4340-90c4-875174316d57';
  }
}

export function getDatadogClientToken() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
  } else {
    return 'pub422a231cf92ec02c659a51bad67d7c15';
  }
}

export function getDatadogSite() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_DATADOG_SITE;
  } else {
    return 'datadoghq.com';
  }
}

export function getDatadogService() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_DATADOG_SERVICE;
  } else {
    return 'factiva-sentiment-signals';
  }
}

export function getDatadogEnv() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_DATADOG_ENV;
  } else {
    return 'prod';
  }
}

export function getApiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL;
  } else {
    return 'https://8p5axscb6g.execute-api.eu-west-1.amazonaws.com/api/';
  }
}

export function getFeatureFlags(): Set<String> {
  const flags = process.env.NODE_ENV === 'development' ? (process.env.REACT_APP_FEATURE_FLAGS ?? '') : 'bulk_loader,new_search,download_chart';
  const split_flags = flags
    .split(',')
    .filter((x) => x)
    .map((x) => x.trim());
  return new Set(split_flags);
}

// https://github.com/facebook/create-react-app/issues/12212
// https://create-react-app.dev/docs/adding-custom-environment-variables/

export function isOktaEnabled(): boolean {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_OKTA_ENABLED === 'true' ? true : false; // this is just false, because in the browser process does not exist
  } else if (process.env.NODE_ENV === 'test') {
    return true;
  } else {
    // @ts-ignore
    return 'true' === 'false' ? false : true;
  }
}

export function isExternalRoutes(): boolean {
  if (process.env.NODE_ENV === 'development') {
    return process.env.USE_EXTERNAL_ROUTES === 'true' ? true : false;
  } else {
    // @ts-ignore
    return 'true' === 'false' ? false : true;
  }
}

export async function digestMessage(message: string | undefined) {
  if (message === undefined) {
    return '';
  }
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest('SHA-256', data);
  const dataView = new DataView(hash);
  return dataView.getUint32(0).toString();
}

export async function GetOktaUserClaims(oktaAuth: OktaAuth): Promise<UserClaims | undefined> {
  if (!oktaAuth) return undefined;
  const tokenManager = oktaAuth.tokenManager;
  const accessToken: AccessToken = (await tokenManager.get('accessToken')) as AccessToken;
  const idToken: IDToken = (await tokenManager.get('idToken')) as IDToken;
  if (!idToken) return undefined;
  const userInfo: UserClaims = await oktaAuth.token.getUserInfo(accessToken, idToken);
  return userInfo;
}
